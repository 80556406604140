import { FreeFortuneFormData } from "features/無料占いBot/hooks/useFreeFortuneForm";
import clients from "shared/repositories/HttpClient";
import { SignInUser } from "shared/types";

interface FormData {
  fortuneId: number;
  email: string;
  password: string;
  passwordConfirmation: string;
  paymentMethod: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCVC: string;
}

type Params = Pick<
  FormData,
  "fortuneId" | "email" | "password" | "passwordConfirmation"
> & { paymentMethod: string; payjpToken: string } & FreeFortuneFormData;

interface Response {
  user: SignInUser;
  order: { id: number };
}

const PaymentWithSignupRepository = {
  post: async (params: Params): Promise<Response> => {
    const res = await clients.miror.post(`/orders/payment_with_signup`, params);
    return res.data;
  },
};

export default PaymentWithSignupRepository;
