import { useEffect, useState } from "react";

export interface UseHostNameReturn {
  host: string | undefined;
}

export const useHostName = (): UseHostNameReturn => {
  const [host, setHost] = useState<string>('https://miror.jp');

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (host !== window.location.origin) {
      setHost(window.location.origin);
    }
  }, [host]);

  return {
    host,
  };
};
