import { ja } from ".";

export const queries = {
  keys: {
    error: "error",
    signUp: "signUp",
    signIn: "signIn",
    signOut: "signOut",
  },
  values: {
    error: {
      default: "default",
      badRequest: "badRequest",
      unauthorized: "unauthorized",
      forbidden: "forbidden",
      notFound: "notFound",
    },
    signUp: {
      success: "success",
    },
    signIn: {
      success: "success",
    },
    signOut: {
      success: "success",
    },
  },
  messages: {
    error: {
      default: ja.error.default,
      badRequest: ja.error.badRequest,
      unauthorized: ja.error.unauthorized,
      forbidden: ja.error.forbidden,
      notFound: ja.error.notFound,
    },
    signUp: {
      success: ja.signUp,
    },
    signIn: {
      success: ja.signIn,
    },
    signOut: {
      success: ja.signOut,
    },
  },
} as const;

export interface Queries {
  error: keyof typeof queries.values[typeof queries.keys.error];
  signUp: keyof typeof queries.values[typeof queries.keys.signUp];
  signIn: keyof typeof queries.values[typeof queries.keys.signIn];
  signOut: keyof typeof queries.values[typeof queries.keys.signOut];
}
