import { BaseRepository } from "shared/repositories/BaseRepository";
import clients from "shared/repositories/HttpClient";

interface TdsStartParams {
  redirectUrl: string;
  payjpToken: string;
}

export interface TdsFinishResponse {
  card: {
    addressCity: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    addressState: string | null;
    addressZip: string | null;
    addressZipCheck: string | "unchecked";
    brand: string | "Visa";
    country: string | null;
    created: number;
    customer: string | null;
    cvcCheck: string | "passed";
    email: string | null;
    expMonth: number;
    expYear: number;
    fingerprint: string;
    id: string;
    last4: string;
    livemode: boolean;
    metadata: object;
    name: string | null;
    object: string | "card";
    phone: string | null;
    threeDSecureStatus: string | "required";
  },
  created: number
  id: string;
  livemode: boolean;
  object: string | "token";
  used: boolean;
}

export class PayjpRepository extends BaseRepository {
  public async tdsStart(params: TdsStartParams) {
    const res = await clients.miror.post(`/payjp/three_d_secure/start`, params);
    return res.data;
  }

  public async tdsFinish(token: string) {
    // const res = await clients.miror.post<GetPayjpCardInfoRes>(`/payjp/three_d_secure/finish`, { payjp_token: token });
    // return res.data;
    const res = await clients.miror.post<TdsFinishResponse>(`/payjp/three_d_secure/finish`, { payjp_token: token });
    return res.data;
  }
};
